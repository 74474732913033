<template>
  <log
    @goPage="goPage"
    @setStartDate="setStartDate"
    @setEndDate="setEndDate"
    :list="list"
    :logData="logData"
    :pageInfo="pageInfo"
    :startDate="startDate"
    :endDate="endDate"
  />
</template>
<script>
import log from "@/components/mypage/pc/log.vue";
import { apiPointLog } from "@/api/user";
import { addCommas } from "@/libs/utils";
export default {
  name: "point",
  emits: ["getUserDetail", "modalOpen"],
  props: ["userDetail"],
  components: {
    log,
  },
  data() {
    return {
      logData: {
        tr: ["포인트", "내용", "일시"],
        td: ["tgtPoint", "desc", "regDt"],
      },
      toggleActive: false,
      list: [],
      model: {
        rowCnt: 20,
        page: 1,
        searchStartDt: "",
        searchEndDt: "",
      },
      pageInfo: {
        page: 1,
        rowCnt: 20,
        totalPageCnt: 1,
      },
      options: [
        { text: "전체", value: 0 },
        { text: "사용내역", value: 1 },
        { text: "획득내역", value: 2 },
      ],
      startDate: this.getDateStr(new Date().setDate(1), "yyyy-MM-dd"),
      endDate: this.getDateStr(new Date(), "yyyy-MM-dd"),
      searchMonth: this.getDateStr(new Date(), "yyyy-MM"),
    };
  },
  watch: {
    searchMonth: {
      handler(value) {
        const fullDate = new Date(value);
        const year = fullDate.getFullYear();
        const month = fullDate.getMonth();
        // console.log(month);
        this.startDate = this.getDateStr(
          new Date(year, month, 1),
          "yyyy-MM-dd"
        );
        this.endDate = this.getDateStr(
          new Date(year, month + 1, 0),
          "yyyy-MM-dd"
        );
      },
    },
    startDate: {
      async handler() {
        await this.getPointLog(1);
      },
    },
    endDate: {
      async handler() {
        await this.getPointLog(1);
      },
    },
    "model.searchRst": {
      async handler() {
        await this.getPointLog(1);
      },
    },
  },
  methods: {
    nextMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month + 1);
    },
    prevMonth() {
      this.searchMonth = new Date(this.searchMonth);
      const month = this.searchMonth.getMonth();
      this.searchMonth = this.searchMonth.setMonth(month - 1);
    },
    setMonth(month) {
      // console.log(month)
      this.searchMonth = month;
    },
    setStartDate(date) {
      this.startDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    setEndDate(date) {
      this.endDate = this.getDateStr(date, "yyyy-MM-dd");
    },
    onSelected(value) {
      this.model.searchRst = value;
    },
    // 포인트 사용 내역 조회
    async getPointLog(page) {
      if (page) {
        this.model.page = page;
      }
      const req = this.model;
      this.model.searchStartDt = this.startDate;
      this.model.searchEndDt = this.endDate;
      const res = await apiPointLog(req);
      if (res.list) {
        for (const item of res.list) {
          let point = String(item.tgtPoint);
          console.log(point.indexOf("-") > -1, "points");
          item.tgtPoint =
            point.indexOf("-") > -1
              ? `-${addCommas(point.slice(1))}`
              : addCommas(point);
          item.desc = item.desc ? item.desc : "-";
        }

        this.pageInfo = res.pageInfo;
        this.list = res.list;
      }
    },
    async goPage(page) {
      await this.getPointLog(page);
    },
    async search() {
      await this.getPointLog();
    },
  },
  async created() {
    await this.getPointLog();
  },
};
</script>
<style scoped></style>
